<template>
  <v-skeleton-loader ref="loader" :loading="status.reading" type="article@3">
    <v-sheet
      v-bind="propsSheetSearchSpace"
      v-if="stateIndex === 'loading'"
      class="mb-4"
    >
      {{ $t("common.loading") }}
    </v-sheet>
    <v-sheet
      v-bind="propsSheetSearchSpace"
      v-if="stateIndex === 'noResults'"
      class="mb-4"
    >
      {{ $t("common.searchNoResults") }}
    </v-sheet>
    <div
      v-if="stateIndex === 'results'"
      data-cy="organisations-index"
      class="mb-4"
    >
      <v-list two-line>
        <v-virtual-scroll
          :items="organisations"
          :height="scrollHeight"
          :item-height="80"
        >
          <template v-slot:default="{ item }">
            <v-list-item
              :to="{ name: 'organisations-show', params: { id: item.id } }"
            >
              <v-list-item-avatar color="transparent">
                <v-img :src="logoUrl(item.id)" contain></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <span>{{ item.name }}</span>
                  <OrganisationLevelChip
                    :levelValue="item.level"
                    class="ml-2"
                  ></OrganisationLevelChip>
                  <v-chip color="red" small v-if="!item.active" class="ml-2">{{
                    $t("organisations.inactive")
                  }}</v-chip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-progress-circular
                        :rotate="90"
                        :size="32"
                        :color="
                          calculateColourDiskSpaceUsed(
                            item.totalFileSize,
                            item.maxFileSize
                          )
                        "
                        :value="
                          calculatePcDiskSpaceUsed(
                            item.totalFileSize,
                            item.maxFileSize
                          )
                        "
                        class="ml-2"
                        v-on="on"
                      >
                        <span style="font-size: 60%">{{
                          calculatePcDiskSpaceUsed(
                            item.totalFileSize,
                            item.maxFileSize
                          )
                        }}</span>
                      </v-progress-circular>
                    </template>
                    <span
                      >{{ prettifyDiskSize(item.totalFileSize) }} /
                      {{ prettifyDiskSize(item.maxFileSize) }}</span
                    >
                  </v-tooltip>
                  <template v-if="isReseller(item)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-progress-circular
                          :rotate="90"
                          :size="32"
                          :color="
                            calculateColourDiskSpaceUsed(
                              item.assignedResellerFileSize,
                              item.maxResellerFileSize
                            )
                          "
                          :value="
                            calculatePcDiskSpaceUsed(
                              item.assignedResellerFileSize,
                              item.maxResellerFileSize
                            )
                          "
                          class="ml-2"
                          v-on="on"
                        >
                          <v-progress-circular
                            :rotate="90"
                            :size="24"
                            :color="
                              calculateColourDiskSpaceUsed(
                                item.totalResellerFileSize,
                                item.maxResellerFileSize
                              )
                            "
                            :value="
                              calculatePcDiskSpaceUsed(
                                item.totalResellerFileSize,
                                item.maxResellerFileSize
                              )
                            "
                            v-on="on"
                          >
                            <span style="font-size: 60%">{{
                              calculatePcDiskSpaceUsed(
                                item.assignedResellerFileSize,
                                item.maxResellerFileSize
                              )
                            }}</span>
                          </v-progress-circular>
                        </v-progress-circular>
                      </template>
                      <span
                        >{{ prettifyDiskSize(item.assignedResellerFileSize) }} (
                        {{ prettifyDiskSize(item.totalResellerFileSize) }}) /
                        {{ prettifyDiskSize(item.maxResellerFileSize) }}</span
                      >
                    </v-tooltip>
                  </template>
                </v-list-item-title>
                <v-list-item-subtitle class="d-flex align-center">
                  <div>
                    {{ item.description }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  v-if="hasImpersonationsFromOrganisation(item.id)"
                  @click.prevent="clickImpersonate(item.id)"
                >
                  <v-icon>$impersonation</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-list>
    </div>

    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <v-btn
        v-if="authCan('create.organisations')"
        v-bind="propsButtonAction"
        :to="{ name: 'organisations-create' }"
        data-cy="button-create"
        >{{ $t("common.new") }}</v-btn
      >
    </div>
  </v-skeleton-loader>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import MiscUtils from "@/modules/base/miscUtils.mixin";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import OrganisationLevelChip from "@/modules/organisations/bits/OrganisationLevelChip";
import { mapGetters } from "vuex";

export default {
  name: "OrganisationsIndex",
  mixins: [Permissions, ComponentStatus, MiscUtils],
  components: {
    OrganisationLevelChip,
  },
  computed: {
    stateIndex() {
      let state = "noSearch";

      if (this.status.loading) {
        state = "loading";
      } else if (this.organisations.length === 0) {
        state = "noResults";
      } else {
        state = "results";
      }
      return state;
    },
    logoUrl() {
      return (id) => {
        return this.$store.getters["organisations/getLogoUrl"](id);
      };
    },
    ...mapGetters({
      organisations: "organisations/readAll",
      hasImpersonationsFromOrganisation:
        "auth/hasImpersonationsFromOrganisation",
    }),
    scrollHeight() {
      if (this.$refs && this.$refs.loader) {
        return this.$refs.loader.$el.clientHeight - 76;
      }
      return 600;
    },
  },
  created() {
    this.setStatusReading();
    this.$store
      .dispatch("organisations/readAll")
      .then((/* result */) => {
        this.setStatusReadSuccess();
      })
      .catch((/* error */) => {
        this.setStatusReadError();
        this.$store.commit("status/showError");
      });
  },
  methods: {
    isReseller(organisation) {
      return organisation.level === APP_CONSTANTS.ORGANISATION_LEVELS_RESELLER;
    },
    clickImpersonate(id) {
      let impersonations =
        this.$store.getters["auth/getImpersonationsFromById"](id);
      // TODO: handle when there is more than one possible case.
      this.$store
        .dispatch("auth/impersonateTo", {
          to_user_id: impersonations[0]["to_user_id"],
          to_organisation_id: impersonations[0]["to_organisation_id"],
        })
        .then((/* result */) => {
          this.setStatusReadSuccess();
          if (
            this.$route.name === "home" ||
            this.$route.name === "documents-index"
          ) {
            this.$router.go();
          } else {
            this.$router.push({ name: "home" });
          }
        })
        .catch((/* error */) => {
          this.setStatusReadError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
