var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-skeleton-loader',{ref:"loader",attrs:{"loading":_vm.status.reading,"type":"article@3"}},[(_vm.stateIndex === 'loading')?_c('v-sheet',_vm._b({staticClass:"mb-4"},'v-sheet',_vm.propsSheetSearchSpace,false),[_vm._v(" "+_vm._s(_vm.$t("common.loading"))+" ")]):_vm._e(),(_vm.stateIndex === 'noResults')?_c('v-sheet',_vm._b({staticClass:"mb-4"},'v-sheet',_vm.propsSheetSearchSpace,false),[_vm._v(" "+_vm._s(_vm.$t("common.searchNoResults"))+" ")]):_vm._e(),(_vm.stateIndex === 'results')?_c('div',{staticClass:"mb-4",attrs:{"data-cy":"organisations-index"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-virtual-scroll',{attrs:{"items":_vm.organisations,"height":_vm.scrollHeight,"item-height":80},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{attrs:{"to":{ name: 'organisations-show', params: { id: item.id } }}},[_c('v-list-item-avatar',{attrs:{"color":"transparent"}},[_c('v-img',{attrs:{"src":_vm.logoUrl(item.id),"contain":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(item.name))]),_c('OrganisationLevelChip',{staticClass:"ml-2",attrs:{"levelValue":item.level}}),(!item.active)?_c('v-chip',{staticClass:"ml-2",attrs:{"color":"red","small":""}},[_vm._v(_vm._s(_vm.$t("organisations.inactive")))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-progress-circular',_vm._g({staticClass:"ml-2",attrs:{"rotate":90,"size":32,"color":_vm.calculateColourDiskSpaceUsed(
                          item.totalFileSize,
                          item.maxFileSize
                        ),"value":_vm.calculatePcDiskSpaceUsed(
                          item.totalFileSize,
                          item.maxFileSize
                        )}},on),[_c('span',{staticStyle:{"font-size":"60%"}},[_vm._v(_vm._s(_vm.calculatePcDiskSpaceUsed( item.totalFileSize, item.maxFileSize )))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.prettifyDiskSize(item.totalFileSize))+" / "+_vm._s(_vm.prettifyDiskSize(item.maxFileSize)))])]),(_vm.isReseller(item))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-progress-circular',_vm._g({staticClass:"ml-2",attrs:{"rotate":90,"size":32,"color":_vm.calculateColourDiskSpaceUsed(
                            item.assignedResellerFileSize,
                            item.maxResellerFileSize
                          ),"value":_vm.calculatePcDiskSpaceUsed(
                            item.assignedResellerFileSize,
                            item.maxResellerFileSize
                          )}},on),[_c('v-progress-circular',_vm._g({attrs:{"rotate":90,"size":24,"color":_vm.calculateColourDiskSpaceUsed(
                              item.totalResellerFileSize,
                              item.maxResellerFileSize
                            ),"value":_vm.calculatePcDiskSpaceUsed(
                              item.totalResellerFileSize,
                              item.maxResellerFileSize
                            )}},on),[_c('span',{staticStyle:{"font-size":"60%"}},[_vm._v(_vm._s(_vm.calculatePcDiskSpaceUsed( item.assignedResellerFileSize, item.maxResellerFileSize )))])])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.prettifyDiskSize(item.assignedResellerFileSize))+" ( "+_vm._s(_vm.prettifyDiskSize(item.totalResellerFileSize))+") / "+_vm._s(_vm.prettifyDiskSize(item.maxResellerFileSize)))])])]:_vm._e()],2),_c('v-list-item-subtitle',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(item.description)+" ")])])],1),_c('v-list-item-action',[(_vm.hasImpersonationsFromOrganisation(item.id))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.clickImpersonate(item.id)}}},[_c('v-icon',[_vm._v("$impersonation")])],1):_vm._e()],1)],1)]}}],null,false,990300195)})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"}),(_vm.authCan('create.organisations'))?_c('v-btn',_vm._b({attrs:{"to":{ name: 'organisations-create' },"data-cy":"button-create"}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.new")))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }