<template>
  <v-container
    v-bind="propsPageMainContainer"
    data-cy="organisations-index-page"
  >
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard" class="d-flex flex-column">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("organisations.indexTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-if="authCan('create.organisations')"
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'organisations-create' }"
              data-cy="button-create"
            >
              <v-icon>$pageCreate</v-icon>
            </v-btn>
          </div>
          <OrganisationsIndex class="flex-grow-1"></OrganisationsIndex>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import OrganisationsIndex from "../components/OrganisationsIndex.vue";

export default {
  name: "OrganisationsIndexPage",
  mixins: [Permissions],
  components: {
    OrganisationsIndex,
  },
};
</script>

<style scoped lang="scss"></style>
